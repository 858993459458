<template>
    <div>
        <div class="searchItem_a">
            <ul class="s_tab_wrap">
                <li @click="activeTab = 1" class="s_tab_item" :class="{ active: activeTab == 1 }">{{ $t('平台轉帳') }}</li>
                <li @click="activeTab = 2" class="s_tab_item" :class="{ active: activeTab == 2 }">{{ $t('轉帳記錄') }}</li>
            </ul>

            <div v-if="activeTab == 1" class="main_platform" v-loading="dataLoading">
                <div class="main_inner">
                    <div class="Myp_box_top">
                        <div class="enter_box">
                            <span class="tf_red">{{ $t("轉出") }}</span>
                            <div class="validatebox-text">
                                <div class="mainAccount">
                                    <span>{{ $t('主帳戶') }}</span>
                                    <span>{{ Number($store.state.userInfo.balance) }}</span>
                                </div>
                            </div>
                        </div>

                        <div class="enter_box">
                            <span class="tf_green">{{ $t("轉入") }}</span>
                            <el-select size="small" v-model="selecTcId" :placeholder="$t('請選擇')"
                                class="validatebox-text">
                                <el-option v-for="item in thirdPlatform" :key="item.tc_id" :label="item.tc_name"
                                    :value="item.tc_id"></el-option>
                            </el-select>
                        </div>

                        <div class="enter_box">
                            <span class="">{{ $t("金額") }}</span>
                            <input v-model="transferAmount" type="text" class="Input_box" :placeholder="$t('請輸入金額')">
                        </div>

                        <button type="button" class="form_button" @click="tripartiteTransfer">{{ $t('確認') }}</button>
                    </div>

                    <div class="Myp_box_center" v-if="$store.state.windowWidth > 768">
                        <div class="form_top">
                            <div class="flex-box">
                                <div class="formBox">
                                    <span class="form_topT">{{ $t('主帳戶') }}</span>
                                    <span class="form_topt tf_green">{{ Number($store.state.userInfo.balance) }}</span>
                                </div>
                                <div class="formBox">
                                    <span class="form_topT">{{ $t('總額度') }}</span>
                                    <span class="form_topt tf_blue">{{ balanceDataState ? platformVisited.reduce((total,
                                        item) => total + Number(item.balance),
                                        Number($store.state.userInfo.balance)).toFixed(2) : $t('載入中...') }}</span>
                                </div>
                            </div>
                            <button type="button" class="btn_backMoney" @click="turnBack">{{ $t('全部轉回主帳戶') }}</button>
                        </div>

                        <div class="projectBox">
                            <div v-for="(item, index) in thirdPlatform" @click="selecTcId = item.tc_id" :key="index"
                                class="projectItem">
                                <div>{{ item.tc_name }}</div>
                                <div class="tf_green">
                                    {{ balanceDataState ? Number(item.balance || 0).toFixed(0) : $t('載入中...') }}</div>
                            </div>
                        </div>
                    </div>

                    <div class="form_dep transit">
                        <ul class="form_dep_content">
                            <li class="form_dep_item">
                                <div class="form_Tp">{{ $t('總額度') }}</div>
                                <div class="form_tranP">
                                    {{ balanceDataState ? platformVisited.reduce((total,
                                        item) => total + Number(item.balance),
                                        Number($store.state.userInfo.balance)).toFixed(2) : $t('載入中...') }}
                                </div>
                            </li>
                            <li class="form_dep_item">
                                <div class="form_Tp">{{ $t('轉出帳戶') }}</div>
                                <div class="form_tranP">
                                    <div class="form_account">
                                        <span>{{ $t('主帳戶') }}</span>
                                        <span class="tf_green">{{ Number($store.state.userInfo.balance) }}</span>
                                    </div>
                                </div>
                            </li>
                            <li class="form_dep_item marginBottom">
                                <div class="form_Tp">{{ $t('轉入帳戶') }}</div>
                                <div class="form_tranP">
                                    <div class="form_account tf_blue">
                                        <el-select size="small" v-model="selecTcId" :placeholder="$t('請選擇')"
                                            class="validatebox-text">
                                            <el-option v-for="(item, index) in thirdPlatform" :key="`tc_${item.tc_id}`"
                                                :label="item.tc_name" :value="item.tc_id"></el-option>
                                        </el-select>
                                        <span v-if="balanceDataState">{{ selecTcId ? Number(thirdPlatform.find(item =>
                                            item.tc_id == selecTcId)?.balance || 0).toFixed(0) : 0 }}</span>
                                        <span v-else>{{$t('載入中...')}}</span>
                                    </div>
                                </div>
                            </li>

                            <li class="form_dep_item">
                                <div class="form_Tp">{{ $t('轉帳金額') }}</div>
                                <div class="form_tranP">
                                    <input type="number" v-model="transferAmount" class="inputTBig2" pattern="[0-9]*"
                                        disabled="disabled" maxlength="8" readonly placeholder="請輸入金額">
                                </div>
                            </li>

                            <div class="keyboard_wrap">
                                <div class="num_keyboard">
                                    <ul class="ul-f">
                                        <li v-for="(key, index) in keys" :key="index" @click="onKeyClick(key)">
                                            {{ key }}
                                        </li>
                                    </ul>
                                </div>
                            </div>

                            <div class="mb_btn_wrap">
                                <button type="button" class="btn_backMoney mb_btn" @click="turnBack">{{ $t('全部轉回主帳戶') }}</button>
                                <button type="button" class="btn_verify mb_btn" @click="tripartiteTransfer">{{ $t('確認送出') }}</button>
                            </div>

                        </ul>

                    </div>
                </div>
            </div>

            <div v-else class="main_platform">
                <platformLog />
            </div>
        </div>
    </div>
</template>

<script>
import { get_third_balance, get_third_platform, third_deposit } from "@/api/index";
import { game_user_transout_api } from "@/api/cybSports";
import platformLog from "./platformLog.vue";
export default {
    components:{
        platformLog
    },
    data() {
        return {
            activeTab: 1,
            selecTcId: '',
            platformVisited: [],
            thirdPlatform: [],
            balanceDataState: false,
            dataLoading: true,
            transferAmount: '',
            transferLoading: false,
            keys: ['1', '2', '3', '4', '5', '←', '6', '7', '8', '9', '0'],
        }
    },
    mounted() {
        if(this.$route.query.tab){
            this.activeTab = 2
        }else{
            this.fetchThirdBalance()
        }
        
    },
    methods: {
        onKeyClick(key) {
            if (key === '←') {
                this.transferAmount = String(this.transferAmount).slice(0, -1);
            } else {
                if (this.transferAmount.length == 8) return
                this.transferAmount += key;
            }
        },
        async turnBack() {
            this.btn_loading = true
            const res = await game_user_transout_api(0)
            this.$store.commit('setThirdBalance', 0);
            this.btn_loading = false
            this.fetchThirdBalance()
            await this.updateUserInfo();
        },
        async tripartiteTransfer() {
            if (this.transferLoading) return
            if (this.selecTcId) {
                if (this.transferAmount && this.transferAmount != 0) {
                    if (Number(this.transferAmount) > Number(this.$store.state.userInfo.balance)) {
                        this.$xwDialog.open({
                            title: this.$t('訊息'),
                            content: this.$t('餘額不足'),
                            hasClose: false,
                            onClose: () => {
                            }
                        });
                        return
                    }
                    this.transferLoading = true
                    const params = {
                        id: this.selecTcId,
                        amount: Number(this.transferAmount)
                    }
                    const response = await third_deposit(params)
                    if (response.data.code == 1) {
                        const transamount = Number(response.data.data.transamount)
                        this.reduceUserBalance(transamount)
                        this.fetchThirdBalance()
                        this.transferLoading = false
                        this.selecTcId = ''
                        this.transferAmount = ''
                        this.$xwDialog.open({
                            title: this.$t('訊息'),
                            content: this.$t('轉入成功！'),
                            hasClose: false,
                            onClose: () => {
                            }
                        });
                    } else {
                        this.errorTips(response.data.msg)
                        this.transferLoading = false

                    }
                } else {
                    this.$xwDialog.open({
                        title: this.$t('訊息'),
                        content: this.$t('請輸入金額！'),
                        hasClose: false,
                        onClose: () => {
                        }
                    });


                }
            } else {
                this.$xwDialog.open({
                    title: this.$t('訊息'),
                    content: this.$t('請選擇轉入帳戶！'),
                    hasClose: false,
                    onClose: () => {
                    }
                });
            }

        },

        // async fetchThirdPlatform() {
        //     const response = await get_third_platform()
        //     if (response.data.code == 1) {
        //         this.thirdPlatform = response.data.data
        //     }
        //     this.dataLoading = false
        // },

        async fetchThirdBalance() {
            this.balanceDataState = false
            const response = await get_third_balance()
            if (response.data.code == 1) {
                this.platformVisited = response.data.data
                this.thirdPlatform = response.data.data
                this.balanceDataState = true
            } else {
                this.errorTips(response.data.msg);
                this.balanceDataState = true
            }
            this.dataLoading = false
        },
        mergeArraysByTcId(arr1, arr2) {
            const map = new Map();
            arr1.forEach(item => {
                map.set(item.tc_id, { ...item });
            });
            arr2.forEach(item => {
                if (map.has(item.tc_id)) {
                    Object.assign(map.get(item.tc_id), item);
                } else {
                    map.set(item.tc_id, { ...item });
                }
            });
            return Array.from(map.values());
        }
    }
}
</script>

<style lang="less" scoped>
.searchItem_a {
    height: 34px;
    width: 780px;

    .s_tab_wrap {
        height: 29px;
        width: 170px;
        margin: -11px auto 0;
        list-style: none;
        display: flex;
        align-items: stretch;
        color: #000;

        .s_tab_item {
            height: 34px;
            width: 85px;
            line-height: 31px;
            text-align: center;
            font-size: 13px;
            list-style: none;
            cursor: pointer;
        }

        .s_tab_item.active {
            border-bottom: 2px solid #3972c7;
            color: #3972c7;
        }
    }

    @media (max-width:768px) {
        width: 100vw;
        background-color: #fff;
        height: 40px;
        // border-bottom: 1px solid #ddd;

        .s_tab_wrap {
            height: 40px;
            margin: auto;

            .s_tab_item {
                height: 40px;
                line-height: 40px;
            }

            .s_tab_item.active {
                border-bottom: 3px solid #3972c7;
            }
        }
    }
}

.main_platform {
    width: 780px;
    padding-top: 6px;

    @media (max-width:768px) {
        width: 100%;
        padding-top: 0;
    }
}

.main_inner {
    padding: 10px;
    background-color: #fff;
    border: 1px solid #d4d4d4;
    box-sizing: border-box;
    height: inherit;
    min-height: 426px;

    @media (max-width:768px) {
        border: none;
        padding: 0;
        background-color: transparent;
    }
}

.Myp_box_top {
    width: 100%;
    background-color: #efefef;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 52px;
    position: relative;
    padding: 0 10px;

    @media (max-width:768px) {
        display: none;
        flex-direction: column;
        height: auto;
        background: transparent;
    }
}

.enter_box {
    display: flex;
    margin: 0 9px 0 14px;
    height: 52px;
    line-height: 52px;
    align-items: center;
    color: #000;
    gap: 10px;

    @media (max-width:768px) {
        width: 100%;
    }
}

.tf_red {
    color: #f00;
}

.tf_green {
    color: #039612;
}

.tf_blue {
    color: #398dc5;
}

.validatebox-text {
    padding-top: 0px;
    padding-bottom: 0px;
    width: 160px;
    border-radius: 5px;
}

::v-deep .el-input__inner {
    color: #000 !important;
    border-radius: 5px;
    height: 35px;
    line-height: 35px;
}

.Input_box {
    height: 35px;
    width: 160px;
    border: 1px solid #ddd;
    box-sizing: border-box;
    border-radius: 5px;
    padding: 0 !important;
    color: #000 !important;
    padding-left: 12px !important;
    font-size: 14px !important;
    border: 1px solid #d4d4d4;
    text-indent: 5px;
    outline: none;
}

.form_button {
    display: block;
    height: 32px;
    line-height: 30px;
    width: 64px;
    font-size: 15px;
    text-align: center;
    cursor: pointer;
    background-color: #398dc5;
    border-radius: 3px;
    box-sizing: border-box;
    color: #fff;
}

.Myp_box_center {
    width: 100%;
    box-sizing: border-box;
}

.form_top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 53px;
    color: #333;
    line-height: 35px;
    padding: 9px 0;
    background-color: #fff;
}

.formBox {
    display: flex;
    margin-left: 14px;
    width: 220px;
}

.form_topT {
    display: block;
    font-size: 15px;
    margin-right: 13px;
    font-weight: normal;
    width: 70px;
}

.form_topt {
    font-weight: bold;
    font-size: 16px;
    padding-left: 5px;
}

.btn_backMoney {
    display: block;
    width: 185px;
    text-align: center;
    height: 35px;
    background-color: #ff9600;
    color: #fff;
    border-radius: 3px;
    cursor: pointer;
    font-size: 15px;
    line-height: 30px;
}

.btn_verify{
    background-color: #398dc5 !important;
    border-radius: 3px;
    width: 49%;
    cursor: auto;
    font-weight: bold;
    color: #fff;
    -webkit-text-fill-color: #fff;
    height: 40px;
}

.mb_btn_wrap{
    padding: 10px;
    display: flex;
    align-items: center;
    gap: 8px;
}

.mb_btn{
    width: 49%;
    height: 40px;
    font-weight: bold;
}

.flex-box {
    display: flex;
    align-items: center;
}

.projectBox {
    max-height: 300px;
    overflow-y: auto;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
}

.projectItem {
    height: 50px;
    width: 182px;
    border: 1px solid #ddd;
    border-radius: 3px;
    padding: 7px 8px;
    font-size: 14px;
    line-height: 19px !important;
    background-color: #fff;
    line-height: 15px;
    color: #333;
}

.mainAccount {
    background-color: #fff;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px;
    border-radius: 5px
}

.form_dep {
    padding-bottom: 8%;
    display: none;

    .form_dep_content {
        display: block;
        margin: 0;
        padding: 0;
        list-style: none;
        width: 100%;
        color: #333;
        .form_dep_item {
            display: table;
            width: 100%;
            padding: 0 4%;
            box-sizing: border-box;
            background-color: #fff;
            border-top: 1px solid #ddd;
            height: 48px;
            position: relative;
            user-select: none;
        }
    }

    @media (max-width:768px) {
        display: block;
    }
}

.form_Tp {
    color: #000;
    font-size: 16px;
    display: table-cell;
    white-space: nowrap;
    padding-right: 8px;
    vertical-align: middle;
    width: 95px;
    text-align: left;
}

.form_tranP {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
}

.form_account {
    font-size: 14px;
    font-weight: bold;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.marginBottom {
    margin-bottom: 12px;
}

.inputTBig2 {
    padding-right: 25px;
    box-sizing: border-box;
    height: 48px;
    line-height: 48px !important;
    width: 100%;
    border: none;
    color: #333;
}

.keyboard_wrap {
    padding: 10px;
    position: relative;
    display: table;
    width: 100%;
}

.num_keyboard {
    width: 100%;
    background: #fff;
    border: 1px solid #2682d5;
    border-radius: 5px;
    position: relative;
    cursor: pointer;
}

.ul-f {
    width: 100%;
    margin: 0;
    padding: 0;
    list-style: none;

    &::after {
        display: block;
        content: "";
        clear: both;
    }
}


.num_keyboard .ul-f li {
    text-align: center;
    width: calc(100% / 6);
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    font-size: 32px;
    color: #2682d5;
    border-right: 1px solid #2682d5;
    position: relative;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    box-sizing: border-box;
    float: left;
    display: block;
}

.ul-f li:nth-child(-n+6) {
    border-bottom: 1px solid #2682d5;
}

.num_keyboard .ul-f li:nth-child(6) {
    border-right: 1px solid transparent;
}


.num_keyboard .ul-f li:last-child {
    width: calc(100% / 3);
    height: 50%;
    border-right: 1px solid transparent;
}



</style>